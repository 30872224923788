import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataTable, FormElements, NotifyIcon } from 'web-components';
import { FilledInput, FormControl, Grid, InputAdornment, InputLabel, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getSelectedMachine } from 'redux/machines/selectors';
import { PlcType } from 'attrs/plcConfigValues';
import { getGatewaysV2 } from '../../../../../../redux/rootSelectors';
import {
  gatewaysV2OrganizationGatewaysAction,
  gatewaysV2OrganizationGatewaysIRISAction
} from '../../../../../../redux/gateways_v2/actions';
import Footer from '../../../../../../components/DataTable/Footer';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../../../../../attrs/pagination';
import { SelectDataItem } from '../../../../../../components/MachineTable/MachineTable.styled';
import { COLOR_PRIMARY } from '../../../../../../attrs/colors';
import { getValueToNullableCell } from '../../../../../../helpers/stringHandler';
import { GATEWAY_CONNECTION_STATUS } from '../../../../../../attrs/gateways';

const labelAmend = 'gateways.overview';

const MachineGatewaysModalTable = ({ organizationId, setSelectedGateway, selectedGateway }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { plcType } = useSelector(getSelectedMachine);
  const {
    loadingState,
    organizationGateways: dataGateways,
    organizationGatewaysIRIS: dataGatewaysIRIS
  } = useSelector(getGatewaysV2);

  const data = plcType.plc_type === PlcType.IRIS_V3 ? dataGatewaysIRIS : dataGateways;

  const [filters, setFilters] = useState({
    serial_number: ''
  });
  const handleFilters = key => e =>
    setFilters({
      ...filters,
      [key]: e.target.value
    });
  const [currentPage, setCurrentPage] = useState(PAGINATION_DEFAULT_START_PAGE);
  const [currentView, setCurrentView] = useState([]);
  const [currentSort, setCurrentSort] = useState({
    colId: 'serial_number',
    order: 'desc'
  });
  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE;

  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(
        gatewaysV2OrganizationGatewaysIRISAction(organizationId, {
          ...filters,
          page: currentPage,
          limit: rowsPerPage,
          sort: currentSort.colId,
          order: currentSort.order
        })
      );
    } else {
      dispatch(
        gatewaysV2OrganizationGatewaysAction(organizationId, {
          ...filters,
          page: currentPage,
          limit: rowsPerPage,
          sort: currentSort.colId,
          order: currentSort.order
        })
      );
    }

    setSelectedGateway({
      serial_number: ''
    });
  }, [dispatch, filters, setSelectedGateway]);

  useEffect(() => {
    if (data && data.data && data.data.length) {
      setCurrentView(
        DataTable.sortFn(
          data.data.map(row => ({
            ...row,
            actual_version: getValueToNullableCell(row.actual_version),
            serial_number_radio: (
              <SelectDataItem>
                <FormElements.RadioButton
                  margin="normal"
                  name="link_gateway"
                  activeColor={COLOR_PRIMARY}
                  onChange={() => setSelectedGateway(row)}
                  checked={selectedGateway?.serial_number === row?.serial_number}
                  disabled={false}
                  id="link_gateway"
                />
                {row.serial_number ? row.serial_number : '---'}
              </SelectDataItem>
            )
          })),
          currentSort.colId,
          currentSort.order.toUpperCase()
        )
      );
    } else {
      setCurrentView([]);
    }
  }, [data, currentSort.colId, currentSort.order, selectedGateway, setSelectedGateway]);

  const handleSelectRow = row => setSelectedGateway(row);
  const handleChangePage = page => {
    setCurrentPage(page);

    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(
        gatewaysV2OrganizationGatewaysIRISAction(organizationId, {
          ...filters,
          page,
          limit: rowsPerPage,
          sort: currentSort.colId,
          order: currentSort.order
        })
      );
    } else {
      dispatch(
        gatewaysV2OrganizationGatewaysAction(organizationId, {
          ...filters,
          page,
          limit: rowsPerPage,
          sort: currentSort.colId,
          order: currentSort.order
        })
      );
    }
  };
  const handleSort = (colId, order) => {
    setCurrentSort({ colId, order });
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(
        gatewaysV2OrganizationGatewaysIRISAction(organizationId, {
          ...filters,
          page: currentPage,
          limit: rowsPerPage,
          sort: colId,
          order
        })
      );
    } else {
      dispatch(
        gatewaysV2OrganizationGatewaysAction(organizationId, {
          ...filters,
          page: currentPage,
          limit: rowsPerPage,
          sort: colId,
          order
        })
      );
    }
  };

  const columns = [
    { id: 'serial_number_radio', label: t('gateways.overview.gateway_id'), sortable: true },
    { id: 'serial_number', label: t(`${labelAmend}.gateway_id`), sortable: true },
    { id: 'actual_version', label: t(`${labelAmend}.system_version`), sortable: false },
    { id: 'type', label: t(`${labelAmend}.type`), sortable: false },
    {
      id: 'connection_status',
      label: t(`${labelAmend}.connection_status`),
      sortable: false,
      cellRenderer: row => {
        const connected = row.connected ? 'CONNECTED' : 'NOT_CONNECTED';
        return (
          <Grid container spacing={0} style={{ display: 'block' }}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <NotifyIcon
                  iconName="antenna"
                  fontSize="small"
                  htmlColor={GATEWAY_CONNECTION_STATUS[connected].color}
                  style={{
                    marginRight: '.5rem'
                  }}
                />
                {t(GATEWAY_CONNECTION_STATUS[connected].label)}
              </div>
            </Grid>
          </Grid>
        );
      }
    }
  ];

  const props = {
    dataSelectorTable: 'machine-details-modal-gateway-table',
    dataSelectorRows: 'machine-details-modal-gateway-table-row',
    isLoading: loadingState.status === 'IN_PROGRESS',
    columns,
    rows: currentView,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={data.total_pages || 1}
        totalLength={data.total_length || 0}
        totalItemsPerPage={data.limit || 0}
        onChange={handleChangePage}
      />
    ),
    onClick: handleSelectRow,
    defaultSort: currentSort,
    sortFn: handleSort
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <FormControl variant="filled">
          <InputLabel htmlFor="gateway_id">{t('gateways.filter.gateway_id')}</InputLabel>
          <FilledInput
            id="gateway_id"
            value={filters.serial_number}
            size="small"
            onChange={handleFilters('serial_number')}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon sx={{ color: '#2BACA4' }} />
              </InputAdornment>
            }
            sx={{
              width: '300px',
              backgroundColor: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#FFFFFF',
                '@media (hover: none)': {
                  backgroundColor: '#FFFFFF'
                }
              },
              '&.Mui-focused': {
                backgroundColor: '#FFFFFF'
              },
              '&.Mui-focused fieldset': {
                backgroundColor: '#FFFFFF'
              }
            }}
          />
        </FormControl>
      </Stack>
      <DataTable hover {...props} />
    </>
  );
};

MachineGatewaysModalTable.propTypes = {
  organizationId: T.string.isRequired,
  setSelectedGateway: T.func.isRequired,
  selectedGateway: T.shape({
    serial_number: T.string
  }).isRequired
};

export default MachineGatewaysModalTable;
